<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '案例展示',
          list: [
            {
              name: '案例分类',
              path: '/mall/case/category'
            },
            {
              name: '案例列表',
              path: '/mall/case/case'
            }
          ]
        }
      ]
    }
  }
}
</script>
